//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Form, FormItem, Input, Button, Checkbox, Image, Tag, TabPane, Tabs, InputNumber, Message, Dialog, Alert } from "element-ui";
import { CountDown } from "vant";
import { mapState } from "vuex";
import { ctrlAccount, ctrlCpg, ctrlPt, ctrlCart, ctrlCpn, ctrlFb, ctrlBase } from "@/controller";
import { Coupon, CouponGet, Share, IntroDialog } from "@/components";
import BaseCard from "@/components/Base/BaseCard/BaseCard.vue";
import BaseRadioGroup from "@/components/Base/BaseRadioGroup/BaseRadioGroup.vue";
import BaseCheckboxGroup from "@/components/Base/BaseCheckboxGroup/BaseCheckboxGroup.vue";
import redict from "@/controller/redirect.js";
import { hkOrder } from '@/hooks'
export default {
    name: "Goods",
    components: {
        [Alert.name]: Alert,
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Input.name]: Input,
        [Image.name]: Image,
        [Tag.name]: Tag,
        [TabPane.name]: TabPane,
        [Tabs.name]: Tabs,
        [InputNumber.name]: InputNumber,
        [Dialog.name]: Dialog,
        [CountDown.name]:CountDown,
        Coupon,
        CouponGet,
        BaseCard,
        BaseRadioGroup,
        BaseCheckboxGroup,
        Share,
        IntroDialog,
    },
    props: {
        ptNbr: String,
    },
    data() {
        return {
            // list: [], //頁面組件列表集合
            swiper: {}, //輪播圖
            ptInfo: {}, //商品信息
            cpg: {}, //活動信息
            actionPopShow: false, //優惠折扣信息彈窗
            isSale: true, //限時特賣倒計時
            day: 0, //天
            hr: 0, //時
            min: 0, //分
            sec: 0, //秒
            statusNotice: { text: "", color: "", bgColor: "" }, //商品狀態公告集合
            memInfo: { memName: "", mobile: "", email: "" }, //會員訊息
            isRemind: false, //到貨通知彈窗
            isLoad: true,
            couponList: [], //彈窗優惠券列表
            cpnNbrs: [], //優惠券編號
            isLogin: false,
            unCpnlist: [], //無門檻領券列表

            suitRadio: null, //相關推薦當前選中項
            currAddiList: [], //當前選中選購列表
            targetActive: false,
            tabsActiveName: null,
            skuList: null,
            goodsNumber: 1, //商品數量
            totalPrice: 0, //總價
            corpDisplayName: null, //推薦人
            checkPtBuyInfo: {
                stat: "available",
            }, //檢測課程是否可買
            courseList:[],//講師下屬課程
        };
    },
    watch: {
        isLogin(newValue) {
            console.log("登入狀態：" + newValue);
            //檢測登入後調用無門檻領券
            if (newValue) {
                //新人券
                if (this.Common.isExist(this.cpnNbrs)) {
                    //無門檻領券
                    this.uncollectCoupon();
                }
                this.checkPtBuy();
            }
        },
        //計算金額
        amtCalcParams: {
            handler: async function(newval) {
                console.log("計算金額");
                this._totalNum();
                this._totalPrice();
            },
            deep: true,
        },
        stat(newValue, oldValue) {
            switch (newValue) {
                case "0":
                    return (this.statusNotice = {
                        text: "商品已下架",
                        color: "#fff",
                        bgColor: "#999",
                    });
                case "1":
                    return (this.statusNotice.text = "商品庫存不足");
                case "2":
                    return (this.statusNotice.text = "");
                case "3":
                // return (this.statusNotice.text = "商品庫存不足，XXX時間預約");
                default:
                    break;
            }
        },
    },
    computed: {
        ...mapState({
            //validateMsg
            msg(state) {
                return state.validate;
            },
            config(state) {
                return state.base.configInfo;
            },
            //系統設定最大購買數量
            quota(state) {
                return state.base.configInfo.quota || 1;
            },
            pages(state) {
                return state.page.pageInfo;
            },
        }),
        //商品最大購買數量
        max() {
            let quota = 1;
            this.Common.isExist(this.ptInfo.CpgInfo.quota) ? (quota = this.ptInfo.CpgInfo.quota) : (quota = this.quota);
            return quota || Infinity;
        },

        isActice() {
            if (this.Common.isExist(this.cpg)) {
                //type不屬於 滿減,滿贈,且不是預告的時候，活動價開啟
                if (this.cpg.type != "discount" && this.cpg.type != "gift" && !this.cpg.isPrediction) {
                    return true;
                }
            }
        },
        //商品狀態
        stat() {
            if (this.Common.isExist(this.ptInfo.BasicInfo)) {
                return this.ptInfo.BasicInfo.stat;
            }
        },
        //在售
        onSale() {
            if (this.Common.isExist(this.ptInfo.BasicInfo)) {
                return this.ptInfo.BasicInfo.stat == 2;
            }
        },
        //下架
        soldOut() {
            if (this.Common.isExist(this.ptInfo.BasicInfo)) {
                return this.ptInfo.BasicInfo.stat == 0;
            }
        },
        //缺貨
        stockout() {
            if (this.Common.isExist(this.ptInfo.BasicInfo)) {
                return this.ptInfo.BasicInfo.stat == 1;
            }
        },
        //預購
        preorder() {
            if (this.Common.isExist(this.ptInfo.BasicInfo)) {
                return this.ptInfo.BasicInfo.stat == 3;
            }
        },
        //商品價格
        goodsPrice: {
            get() {
                if (this.Common.isExist(this.ptInfo)) {
                    let obj = {
                        label: "定價",
                        value: this.ptInfo.BasicInfo.price,
                    };
                    //普通價格
                    this.ptInfo.BasicInfo.isOrig ? (obj.label = "直售價") : (obj.label = "定價");

                    // 活動價格
                    if (this.Common.isExist(this.cpg)) {
                        if (this.Common.isExist(this.cpg.isPrediction)) {
                            obj.label = "定價";
                            obj.value = this.ptInfo.BasicInfo.price;
                        } else {
                            obj.label = "活動價";
                            obj.value = this.ptInfo.CpgInfo.cpgPrice;
                        }
                    }

                    //存在商品選項 默認選取第一項的價格
                    if (this.Common.isExist(this.ptInfo._skuList)) {
                        const skuList = this.ptInfo._skuList.filter((item) => !item.disabled);
                        if (this.Common.isExist(skuList)) {
                            obj.value = skuList[0].price;
                        }
                    }
                    return obj;
                }
            },
            set(v) {},
        },
        //參與金額計算參數
        amtCalcParams() {
            const { goodsNumber, currAddiList } = this;
            return {
                goodsNumber,
                currAddiList,
            };
        },
        //活動預告詳情
        preInfo() {
            if (this.Common.isExist(this.cpg) && this.cpg.isPrediction) {
                const time = this.dayjs.unix(this.cpg.start).format("MM月DD日 HH:mm");
                let info = `${time} 活動價 ${this.ptInfo.CpgInfo.cpgPrice}元 `;
                return info;
            }
        },
        //商品編號
        _ptNbr() {
            return this.ptNbr.split("_")[0];
        },
        //定時器時間
		time() {
			const limitDay = 30;
			if (this.Common.isExist(this.cpg)) {
				//滿減，滿贈活動不顯示倒計時
				// if (this.cpg.type == "discount" || this.cpg.type == "gift") {
				//     this.isSale = false;
				//     return 0;
				// }
				let dval = this.cpg.end - Date.now() / 1000;
				let day = dval / 86400;
				this.isSale = true;
				return dval * 1000;
				// if (day <= limitDay) {
				//     this.isSale = true;
				//     return dval * 1000;
				// } else {
				//     this.isSale = false;
				// }
			}
		},
    },

    mounted() {
    },
    destroyed() {
    },
    created() {
        this.getInit();
        //獲取訂單來源
        this.setCorpSrc();
        //領券分享成功
        if (this.Common.isExist(this.$route.query.t)) {
            setTimeout(() => {
                this.shareCollectCoupon();
            }, 1500);
        }
    },
    methods: {
        //是否登入
        async chkLogin() {
            this.isLogin = ctrlAccount.chkLogin();
        },
        //檢測商品是否可買
        async checkPtBuy() {
            this.checkPtBuyInfo = await ctrlPt.checkPtBuy({ ptNbr: this._ptNbr });
        },
        async getInit() {
            const params = this.$route.params;

            this.ptInfo = await ctrlPt.getPtInfo2({ ptNbr: this._ptNbr }, params.fun);
            console.log("this.ptInfo");
            console.log(this.ptInfo);
            if (this.Common.isExist(this.ptInfo)) {
                const cpgNbr = this.ptInfo.CpgInfo.cpgNbr;
                document.title = this.ptInfo.BasicInfo.title;
                // this.goodsNumber = 1;
                //套裝初始化 組件參數
                if (this.Common.isExist(this.ptInfo.PackageList)) {
                    for (const pack of this.ptInfo.PackageList) {
                        for (const item of pack.Rows) {
                            item._id = item.ptNbr;
                            item._img = item.img;
                            item._name = item.name;
                            item._desc = item.desc;
                            item._price = item.price;
                            params.fun == "buy" && (item.redirectUrl = item.redirectUrl.replace(/\/item\//, "/buy/"));
                        }
                        const value = pack.Rows.find((item) => item.isActi == 1);
                        if (value) {
                            this.suitRadio = value.ptNbr;
                        }
                    }
                }

                //選購 初始化組件參數
                this.ptInfo.AdditionList.forEach((item) => {
                    item._id = item.skuId;
                    item._img = item.img;
                    item._name = item.name;
                    item._desc = item.desc;
                    item._price = item.price;
                    item.price_original = item.origPrice;
                    item._origPrice = item.origPrice;
                    item.qtyOrd = 1;
                    item.max = 1;
                });
                //Tabs 默認選中項
                if (this.Common.isExist(this.ptInfo.DetailInfo)) {
                    this.tabsActiveName = this.ptInfo.DetailInfo[0].title;
                }else {
                    this.tabsActiveName = '0'
                }
                if (cpgNbr) {
                    this.cpg = await ctrlCpg.getCpgInfo({ fun: this.$route.params.fun, cpgNbr: cpgNbr });

                    // this.isActice && this.countdown();
                }

                if (this.Common.isExist(this.ptInfo.CpnList)) {
                    this.cpnNbrs = this.ptInfo.CpnList.map((item) => item.cpnNbr);
                }

                this.getCourseList()

                this.chkLogin();
            }
        },
        //根據講師nbr獲取課程列表
        async getCourseList(){
            this.courseList = await ctrlBase.getPtList({cgNbr:this.ptInfo.CgInfo.cgNbr})
        },
        //計時器時間停止 限時特賣結束了
		async timeFinish() {
			this.isSale = false;
			this.cpg = {};
			const data = await ctrlPt.dealPtInfoTick({ ptNbr: this._ptNbr });
			if (data) {
				this.ptInfo = await ctrlPt.getPtInfo({ ptNbr: this._ptNbr });
			}
		},
        redirect(val) {
            redict.push(val, this.$route.params.fun);
            // console.log(val);
        },
        suitRadioChange(item) {
            this.redirect(item);
        },
        checkboxChangeHandle(option, item) {
            this.currAddiList = option;
        },
        //免費商品購買
        async addFreeOrder(){
           const data = await hkOrder.addFreeOrder(this.ptInfo.SkuInfo.collectionId,this.$route.path);
           if(data?.retCode == 1){
               Message.success("領取成功");
               this.$router.push("/course/index");
           }
        },
        //立即購買
        addBuy() {
            ctrlBase.gaSentEvent("商品頁進入", "立即購買", this.ptInfo.BasicInfo.title);
            const basicInfo = this.ptInfo.BasicInfo;
            //構造主商品數據
            let Optd = {
                skuId: this.ptInfo.SkuInfo.collectionId,
                price: basicInfo.price,
                qtyOrd: this.goodsNumber,
                imgUrl: basicInfo.img,
                ptName: basicInfo.ptName,
                name: basicInfo.name, //選項名
            };
            let Addition = this.currAddiList;
            let Access = [];
            const ptNbr = this.ptInfo.ptNbr;
            if (this.ptInfo.AccesList) Access = this.ptInfo.AccesList.filter((acc) => acc.skuId != "main");
            let cart = {
                ptNbr,
                Optd,
                Addition,
                Access,
            };
            this.ptInfo.CpgInfo.cpgNbr && (cart.cpgNbr = this.ptInfo.CpgInfo.cpgNbr);

            let goodsInfo = {
                sourceType: 1,
                PtList: [],
            };
            this.Common.isExist(this.ptInfo.CpnList) && (goodsInfo["CpnList"] = this.ptInfo.CpnList);
            goodsInfo.PtList.push(cart);
            ctrlCart.addBuyNow(goodsInfo);
        },
        //無門檻領券
        async uncollectCoupon() {
            await this.getCouponByCpn();
            const unCpnNbrs = this.couponList.filter((item) => item.stat == -1).map((ele) => ele.cpnNbr);
            //沒有未領取的無門檻券 直接return
            if (!this.Common.isExist(unCpnNbrs)) return false;

            //test
            // this.unCpnlist = [
            //     {
            //         amtDisc: 50,
            //         couponNbr: "1",
            //         cpnNbr: "h4ds16",
            //         desc: "滿300減50",
            //         discPer: 0,
            //         discType: 2,
            //         effdate: "2021-04-15",
            //         expire: "2023-04-15",
            //         img: "",
            //         link: "/promo/index/h4ds16",
            //         name: "踏青出遊季休閑零食(滿300減100)",
            //         stat: -1,
            //         tagName: "無門檻滿減",
            //     },
            // ];
            // this.$refs.couponGet.couponShow = true;

            const data = await ctrlCpn.collectCoupon({ cpnNbrs: unCpnNbrs.toString(), scene: 0 });
            if (data) {
                this.unCpnlist = data;
                this.$refs.couponGet.couponShow = true;
            }
        },
        //輪播圖 顯示選項圖片
        skuChangeImg(item) {
            if (this.Common.isExist(this.skuList) && this.skuList.length > 1 && this.Common.isExist(item.imgUrl)) {
                if (this.Common.isExist(this.ptInfo.Medias)) this.$set(this.ptInfo.Medias, "skuImg", { img: item.imgUrl, link: "" });
                if (this.Common.isExist(this.ptInfo.Medias.imgList)) {
                    this.$nextTick(() => {
                        this.$refs.hvSwiper.$refs.mySwiper.setActiveItem("skuImg");
                    });
                }
            }
        },
        //獲取優惠券列表
        async getCouponByCpn() {
            const cpnNbrs = this.ptInfo.CpnList.map((item) => item.cpnNbr);
            this.couponList = await ctrlCpn.getCouponByCpn({ cpnNbrs: this.cpnNbrs.toString() });
            // this.couponList = [
            //     {
            //         amtDisc: 50,
            //         couponNbr: "",
            //         cpnNbr: "h4ds16",
            //         desc: "滿300減50",
            //         discPer: 0,
            //         discType: 2,
            //         effdate: "2021-04-15",
            //         expire: "2023-04-15",
            //         img: "",
            //         link: "/promo/index/h4ds16",
            //         name: "踏青出遊季休閑零食(滿300減100)",
            //         stat: -2,
            //         tagName: "滿減",
            //     }
            // ];
            // console.log(this.couponList);
        },
        //分享領券
        async shareCollectCoupon() {
            const ret = await ctrlFb.chkShareRes({ t: this.$route.query.t });
            if (ret) {
                const data = await ctrlCpn.collectCoupon({ cpnNbrs: ret, scene: 1 });
                if (data) {
                    this.unCpnlist = data;
                    this.$refs.couponGet.couponShow = true;
                }
            }
        },
        //客服
        onlineService() {
            ctrlFb.onlineService(this.config, {
                ptNbr: this._ptNbr,
            });
        },
        //到貨通知
        async addNotice(val) {
            this.$refs.remindForm.validate(async (valid) => {
                if (!valid) {
                    console.log("error submit!!");
                    return false;
                }
                const obj = {
                    noticeName: val.memName,
                    noticeTel: val.mobile,
                    noticeEmail: val.email,
                    ptNbr: this.ptInfo.ptNbr,
                };
                const res = await ctrlPt.addNotice(obj);

                if (res) this.isRemind = false;
            });
        },
        //獲取訂單來源
        async setCorpSrc() {
            //預覽路由 不請求接口
            if (this.$route.name == "preview") return;
            const corpNbr = this.$route.params.corpNbr;
            if (corpNbr == "index") return;
            this.corpDisplayName = await ctrlCpg.setCorpSrc({ corpType: "co", corpNbr });
        },
        _totalNum() {
            if (this.ptInfo.AdditionList.length) {
                this.ptInfo.AdditionList.forEach((item) => {
                    item.qtyOrd = this.goodsNumber;
                });
            }
        },
        _totalPrice() {
            this.totalPrice = this.goodsPrice.value * this.goodsNumber;
            if (this.currAddiList.length) {
                this.currAddiList.forEach((item) => {
                    this.totalPrice += item.qtyOrd * item.price;
                });
            }
        },
    },
};
