//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";
import redict from "@/controller/redirect.js";
import { Image, Card, Button, Col, Row } from "element-ui";
Vue.use(Image)
   .use(Card)
   .use(Button)
   .use(Col)
   .use(Row);
export default {
    props:{
        option: {
            type: Array,
            default: ()=>[]
        },
        //跳轉類型 1.轉址 2.重定向 3.新開窗口 999.跳轉團購活動頁group/{cpgNbr}/{grpmNbr}
        redictType:{
            type:Number,
            default: 1
        },
        isShowDesc:{
            type:Boolean,
            default:true
        }
    },
    name: "BaseCard",
    components: {
    },
    data() {
        return {
        };
    },
    watch: {
    },
    computed:{
    },
    mounted() {
    },
    created() {
    },
    methods: {
        // optionChange(item){
        //     this.$emit('optionChange',this.currCheckboxChangeChild,item)
        // },
        redirect(val,type) {
            if(type == 999){
                //跳轉自定義地址
                const routeUrl = this.$router.resolve({
                    path: val.url,
                });
                window.open(routeUrl.href, "_blank");
                return;
            }
            redict.push(val,  this.$route.params.fun);
        },
    },
};
